import React from 'react'
import styled, { css } from 'styled-components'
import KeyHandler from 'react-key-handler'
import media from '../media'
import arrowNext from './arrow-next.svg'
import arrowPrevious from './arrow-previous.svg'

const Outer = styled.div`
  width: 100%;
  height: 100%
  position: relative;
  background-color: #fcfcfc;
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: auto !important;
  max-height: 100%;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${media.phablet`
    position: static;
  `};
`

const Top = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 160px;
  left: 0;
  overflow: hidden;

  ${media.phablet`
    position: static;
    height: 320px;
  `};
`

const Thumbs = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  display: flex;
  justify-content: space-between;

  ${media.phablet`
    position: static;
    padding: 20px 20px 0 20px;
    flex-wrap: wrap;
    height: auto;
    justify-content: start;
  `};
`

const ThumbButton = styled.button`
  border: 0;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  flex-grow: 1;
  max-width: 250px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.1;
  cursor: pointer;
  transition: opacity 300ms ease-out;
  background-image: url('${props => props.image}');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    opacity: 1;
    ${media.phablet`
      opacity: 0.33;
    `}
  }

  ${media.phablet`
    position: static;
    flex-grow: 1;
    width: 80px;
    height: 80px;
    margin: 0;
    opacity: 0.33;
  `};

  ${props =>
    props.active &&
    css`
      opacity: 1 !important;
    `};
`

const Button = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 150px;
  background-color: white;

  & > img {
    margin-bottom: 0;
  }

  ${media.phablet`
    display: none;
  `};

  ${props =>
    props.disabled
      ? css`
          opacity: 0.1;
        `
      : css`
          cursor: pointer;
        `};
`

const Empty = styled.div``

class ModalSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      Carousel: Empty,
    }
  }

  componentDidMount() {
    import('../../nuka-carousel.min').then(Carousel => {
      this.setState({ Carousel: Carousel.default })
    })
  }

  render() {
    const { photos } = this.props
    const { Carousel } = this.state
    return (
      <Container>
        <KeyHandler
          keyEventName="keydown"
          keyCode={39}
          onKeyHandle={e => {
            this.setState(s => {
              if (s.index < photos.length - 1) {
                e.preventDefault()
                return { index: s.index + 1 }
              }
            })
          }}
        />
        <KeyHandler
          keyEventName="keydown"
          keyCode={37}
          onKeyHandle={e => {
            this.setState(s => {
              if (s.index > 0) {
                e.preventDefault()
                return { index: s.index - 1 }
              }
            })
          }}
        />
        <Top>
          <Carousel
            slideIndex={this.state.index}
            afterSlide={index => this.setState({ index })}
            cellAlign="center"
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderBottomCenterControls={() => null}
            heightMode="100%"
          >
            {photos.map(p => (
              <Outer key={p.node.id}>
                <Inner>
                  <Image src={p.node.large.resize.src} key={p.node.id} />
                </Inner>
              </Outer>
            ))}
          </Carousel>
        </Top>
        <Thumbs>
          <Button
            disabled={this.state.index === 0}
            onClick={() => this.setState(s => ({ index: s.index - 1 }))}
          >
            <img src={arrowPrevious} alt="Previous" />
          </Button>
          {photos.map((p, index) => (
            <ThumbButton
              active={this.state.index === index}
              onClick={() => this.setState({ index })}
              key={p.node.id}
              image={p.node.thumb.resize.src}
            />
          ))}
          <Button
            disabled={this.state.index === photos.length - 1}
            onClick={() => this.setState(s => ({ index: s.index + 1 }))}
          >
            <img src={arrowNext} alt="Next" />
          </Button>
        </Thumbs>
      </Container>
    )
  }
}

// const ModalSlider = ({ photos }) => (
//   <ImageGallery
//     items={photos.map(p => ({
//       // srcSet: p.node.large.sizes.srcSet,
//       original: p.node.large.sizes.src,
//       thumbnail: p.node.thumb.sizes.src,
//     }))}
//     showFullscreenButton={false}
//     showPlayButton={false}
//     showNav={false}
//     renderItem={item => (
//       <Outer>
//         <Inner>
//           <Image
//             src={item.original}
//             alt={item.originalAlt}
//             srcSet={item.srcSet}
//             sizes={item.sizes}
//             title={item.originalTitle}
//           />
//         </Inner>
//       </Outer>
//     )}
//   />
// )

export default ModalSlider
