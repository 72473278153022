import React from 'react'
import styled, { keyframes } from 'styled-components'
import { brand } from '../colours'
import heroProject from './hero-project.jpg'
import heroLogo from './hero-logo.png'
import media from '../media'

const Container = styled.div`
  position: relative;
  background-color: ${brand};
`

const Hero = styled.div`
  height: 720px;
  background-image: url(${heroProject});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Cta = styled.div`
  position: relative;
  background-color: ${brand};
  color: white;
  text-align: center;
  font-size: 15px;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${brand};
    border-width: 13px;
    margin-left: -13px;
  }

  & > a,
  & > a:visited {
    color: white;
  }
`

const Text = styled.p`
  margin: 0;
  color: white;
  text-shadow: 0 2px 3px black;
  font-size: 20px;
  line-height: 100px;
  text-align: center;

  ${media.phablet`
    padding: 20px 10px 20px 10px;
    font-size: 16px;
    line-height: 32px;
  `};
`

const Logo = styled.img`
  display: block;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 230px;

  ${media.phablet`
    width: 140px;
    bottom: 100px;
  `};

  ${media.phone`
    display: none;
  `};
`

const SectionAboutUs = () => (
  <Container>
    <Hero>
      <Logo src={heroLogo} />
    </Hero>
    <Cta>
      <Text>
        We are award-winning, highly skilled craftsmen that build quality homes.
      </Text>
    </Cta>
  </Container>
)

export default SectionAboutUs
