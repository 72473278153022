import React from 'react'
import styled, { css } from 'styled-components'
import Waypoint from 'react-waypoint'
import ReactMedia from 'react-media'
import Modal from 'react-modal'
import noScroll from 'no-scroll'
import Helmet from 'react-helmet'
import queryString from 'query-string'
import FloatHeader from '../components/float-header'
import Section from '../components/section'
import SectionTitle from '../components/section-title'
import SectionAboutUs from '../components/section-about-us'
import SectionContactUs from '../components/section-contact-us'
import SectionServices from '../components/section-services'
import SectionAwards from '../components/section-awards'
import SectionGallery from '../components/section-gallery'
import SectionTestimonials from '../components/section-testimonials'
import ModalProject from '../components/modal-project'
import modalClose from '../components/modal-close.svg'
import media, { sizes } from '../media'
import { brand } from '../colours'
import favicon from './favicon.png'

import './index.css'

const menuItems = [
  { hash: '#top', name: 'About Us' },
  { hash: '#services', name: 'Services' },
  { hash: '#awards', name: 'Awards' },
  { hash: '#projects', name: 'Projects' },
  { hash: '#testimonials', name: 'Testimonials' },
  { url: 'https://www.instagram.com/jshendersonbuilders/', name: 'Instagram' },
  { hash: '#contact-us', name: 'Contact Us' },
]

const Footer = styled.div`
  background-color: ${brand}
  padding: 50px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 300;
`

const ModalCloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 37px;
  height: 37px;
  cursor: pointer;
  z-index: 5000;
  color: ${brand};

  ${media.phone`
    top: 7px;
    right: 7px;
  `};
`

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  ${props =>
    props.spaced
      ? css`
          flex: 1;
          justify-content: space-around;
        `
      : css`
          & > * {
            margin-left: 2vw;
          }
        `};
`

const MenuItem = styled.li`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  white-space: nowrap;
`

const buttonStyles = css`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 13px;
`

const ActiveButton = styled.a`
  ${buttonStyles} ${props =>
    props.reverse
      ? css`
          color: ${brand};
          border-bottom: 1px solid ${brand};

          &:visited {
            color: ${brand};
          }
        `
      : css`
          color: white;
          border-bottom: 1px solid white;

          &:visited {
            color: white;
          }
        `};
`

const InactiveButton = styled.a`
  ${buttonStyles} ${props =>
    props.reverse
      ? css`
          color: ${brand};

          &:visited {
            color: ${brand};
          }
        `
      : css`
          color: white;

          &:visited {
            color: white;
          }
        `};
`

const SectionReversed = styled.div`
  background-color: ${brand};
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center center;
  padding-bottom: 50px;
`

const MobileMenu = styled.ul`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 5000;
  background-color: rgba(25, 51, 69, 0.8);
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px 0;

  & > li {
    text-align: center;
    font-size: 25px;
    padding: 12px;
  }
`

function getHash(id) {
  if (id === 'top') {
    return ''
  }
  return `#${id}`
}

const LargeText = styled.p`
  margin: 0 auto;
  max-width: 940px;
  padding: ${props => (props.tight ? '50px' : '100px')} 10px;
  text-align: center;
  font-size: 19px;
  line-height: 33px;

  ${media.phone`
    font-size: 14px;
    line-height: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
  `};
`

const StyledModal = styled(Modal)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: hidden;
  padding: 0;

  ${media.phone`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `};
`

const MenuButton = ({ onClick }) => <button onClick={onClick}>Menu</button>

function getPhotosByDirectory(allFile) {
  if (!allFile || !allFile.edges) {
    return {}
  }
  return allFile.edges.reduce((acc, edge) => {
    if (acc[edge.node.relativeDirectory]) {
      acc[edge.node.relativeDirectory].push(edge)
    } else {
      acc[edge.node.relativeDirectory] = [edge]
    }
    return acc
  }, {})
}

function isModalOpen(location) {
  return queryString.parse(location.search).project !== undefined
}

const sectionIds = [
  'top',
  'services',
  'awards',
  'projects',
  'testimonials',
  'contact-us',
]

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hash: typeof window === 'undefined' ? '' : window.location.hash,
      floatNav: false,
      menuOpen: false,
      photosByDirectory: getPhotosByDirectory(props.data.allFile),
      testimonialsSlideIndex: 0,
      hasMounted: false,
    }
    this.visibleSectionIds = []
    this.onSectionWaypoint = this.onSectionWaypoint.bind(this)
    this.closeProjectModal = this.closeProjectModal.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      photosByDirectory: getPhotosByDirectory(nextProps.data.allFile),
    })
  }

  componentDidMount() {
    this.setState({ hasMounted: true })
    window.addEventListener(
      'hashchange',
      () => {
        this.setState({ hash: window.location.hash })
      },
      false
    )
  }

  componentDidUpdate(prevProps) {
    const wasOpen = isModalOpen(prevProps.location)
    const isOpen = isModalOpen(this.props.location)
    const didOpen = !wasOpen && isOpen
    const didClose = wasOpen && !isOpen

    if (didOpen) {
      noScroll.on()
      document.documentElement.style.scrollBehavior = 'auto'
    } else if (didClose) {
      noScroll.off()
      document.documentElement.style.scrollBehavior = ''
    }
  }

  onSectionWaypoint({ id, event, action }) {
    const { visibleSectionIds } = this

    let nextVisibleSectionIds
    if (action === 'leave') {
      nextVisibleSectionIds = visibleSectionIds.filter(
        visibleSectionId => visibleSectionId !== id
      )
    } else if (event.previousPosition === 'above') {
      nextVisibleSectionIds = [id, ...visibleSectionIds]
    } else {
      nextVisibleSectionIds = [...visibleSectionIds, id]
    }

    let nextId
    if (action === 'leave' && event.currentPosition === 'below') {
      nextId =
        sectionIds[sectionIds.findIndex(sectionId => sectionId === id) - 1]
    } else if (action === 'leave' && event.currentPosition === 'above') {
      nextId = nextVisibleSectionIds[0]
    } else if (action === 'enter' && event.previousPosition === 'below') {
      nextId = nextVisibleSectionIds[0]
    } else if (action === 'enter' && event.previousPosition === 'above') {
      nextId = sectionIds[sectionIds.findIndex(sectionId => sectionId === id)]
    }

    if (nextId) {
      const hash = getHash(nextId)
      window.history.replaceState(null, null, document.location.pathname + hash)
      this.setState({ hash })
    }

    this.visibleSectionIds = nextVisibleSectionIds
  }

  closeProjectModal() {
    this.props.history.push(document.location.pathname + this.state.hash)
  }

  render() {
    const { data } = this.props
    const {
      hash,
      floatNav,
      menuOpen,
      photosByDirectory,
      testimonialsSlideIndex,
      hasMounted,
    } = this.state
    const testimonialImages = [
      photosByDirectory['Angus Kennedy'][0].node.large.fluid.src,
      photosByDirectory['Woolard'][1].node.large.fluid.src,
      photosByDirectory['Lawson St'][1].node.large.fluid.src,
    ]

    const parsedQueryString = queryString.parse(this.props.location.search)
    const modalProjectSlug = parsedQueryString.project

    const modalOpen = isModalOpen(this.props.location)
    const modalProject = data.allMarkdownRemark.edges.find(
      e => e.node.frontmatter.slug === modalProjectSlug
    )
    const sectionProps = id => ({
      id,
      onSectionWaypoint: this.onSectionWaypoint,
    })
    return (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'We are award winning, highly skilled craftsmen helping people like you make the right decisions so their dream homes become reality.',
            },
            { name: 'keywords', content: 'builders' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
          ]}
        />
        {menuOpen && (
          <MobileMenu onClick={() => this.setState({ menuOpen: false })}>
            {menuItems.map(menuItem => {
              const Button =
                menuItem.hash === hash ||
                (menuItem.hash === '#top' && hash === '')
                  ? ActiveButton
                  : InactiveButton
              return (
                <MenuItem key={menuItem.hash || menuItem.url}>
                  <Button
                    reverse={false}
                    href={menuItem.hash || menuItem.url}
                    target={menuItem.url ? '_blank' : '_self'}
                  >
                    {menuItem.name}
                  </Button>
                </MenuItem>
              )
            })}
          </MobileMenu>
        )}
        <StyledModal
          isOpen={modalOpen}
          onRequestClose={this.closeProjectModal}
          appElement={
            typeof document !== 'undefined' &&
            document.getElementById('___gatsby')
          }
        >
          <ModalCloseButton onClick={() => this.closeProjectModal()}>
            <img src={modalClose} alt="Close" />
          </ModalCloseButton>
          <ModalProject
            project={modalProject}
            photosByDirectory={photosByDirectory}
          />
        </StyledModal>
        <div style={menuOpen || modalOpen ? { filter: 'blur(10px)' } : {}}>
          <Waypoint
            onEnter={event => {
              this.setState({ floatNav: false })
            }}
            onLeave={event => {
              if (event.currentPosition === 'above') {
                this.setState({ floatNav: true })
              } else {
                this.setState({ floatNav: false })
              }
            }}
          />
          <Section {...sectionProps('top')}>
            {hasMounted && (
              <ReactMedia query={`(max-width: ${sizes.phone}px)`}>
                {matches =>
                  matches ? (
                    <FloatHeader
                      floating
                      hideCta
                      siteTitle={data.site.siteMetadata.title}
                    >
                      <MenuButton
                        onClick={() => this.setState({ menuOpen: true })}
                      />
                    </FloatHeader>
                  ) : (
                    <FloatHeader
                      floating={floatNav}
                      siteTitle={data.site.siteMetadata.title}
                    >
                      <Menu spaced={!floatNav}>
                        {menuItems.map(menuItem => {
                          const Button =
                            menuItem.hash === hash ||
                            (menuItem.hash === '#top' && hash === '')
                              ? ActiveButton
                              : InactiveButton
                          return (
                            <MenuItem key={menuItem.hash || menuItem.url}>
                              <Button
                                reverse={floatNav}
                                href={menuItem.hash || menuItem.url}
                                target={menuItem.url ? '_blank' : '_self'}
                              >
                                {menuItem.name}
                              </Button>
                            </MenuItem>
                          )
                        })}
                      </Menu>
                    </FloatHeader>
                  )
                }
              </ReactMedia>
            )}
            <SectionAboutUs />
            <LargeText>
              Building excellence on the NSW North Coast, covering Lismore,
              Byron Bay, Ballina and all areas in between. Our expertise is with
              individually designed projects. We have over 35 years experience
              in this region, working alongside leading architects and
              designers, providing personal service and attention to every
              detail in your new home, extension, renovation or commercial
              project. Top quality and highly skilled craftspeople in every
              stage, making superiority of the finished project our highest
              priority.
            </LargeText>
          </Section>
          <Section {...sectionProps('services')}>
            {({ hasEntered }) => [
              <SectionTitle
                key="title"
                secondary="What we do"
                hasEntered={hasEntered}
              >
                Services
              </SectionTitle>,
              <SectionServices key="content" hasEntered={hasEntered} />,
            ]}
          </Section>
          <Section {...sectionProps('awards')} id="awards">
            {({ hasEntered }) => [
              <SectionTitle
                key="t"
                secondary="Our work being recognised"
                hasEntered={hasEntered}
              >
                Awards
              </SectionTitle>,
              <SectionAwards key="c" hasEntered={hasEntered} />,
            ]}
          </Section>
          <Section {...sectionProps('projects')} id="projects">
            {({ hasEntered }) => [
              <SectionTitle
                key="t"
                secondary="What we've done"
                hasEntered={hasEntered}
              >
                Projects
              </SectionTitle>,
              <SectionGallery
                key="g"
                onOpenProject={slug => {
                  this.props.history.push(`?project=${slug}#projects`)
                }}
                projects={data.allMarkdownRemark.edges}
                photosByDirectory={photosByDirectory}
                hasEntered={hasEntered}
              />,
            ]}
          </Section>
          <Section {...sectionProps('testimonials')} id="testimonials">
            {({ hasEntered }) => (
              <SectionReversed
                image={testimonialImages[testimonialsSlideIndex]}
              >
                <SectionTitle
                  secondary="What our clients say about us"
                  flourish={false}
                  reversed
                  hasEntered={hasEntered}
                >
                  Testimonials
                </SectionTitle>
                <SectionTestimonials
                  hasEntered={hasEntered}
                  slideIndex={testimonialsSlideIndex}
                  afterSlide={i => this.setState({ testimonialsSlideIndex: i })}
                />
              </SectionReversed>
            )}
          </Section>
          <Section {...sectionProps('contact-us')} id="contact-us">
            {({ hasEntered }) => [
              <SectionTitle
                key="t"
                secondary="How to reach us"
                flourish={false}
                hasEntered={hasEntered}
              >
                Contact Us
              </SectionTitle>,
              <SectionContactUs key="c" hasEntered={hasEntered} />,
            ]}
          </Section>
          <Footer>JS Henderson &copy; 2018</Footer>
        </div>
      </div>
    )
  }
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: fileAbsolutePath }) {
      edges {
        node {
          id
          html
          frontmatter {
            slug
            type
            title
            directory
            poster
          }
        }
      }
    }
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          id
          thumb: childImageSharp {
            resize(width: 1000, quality: 25, cropFocus: ENTROPY) {
              src
            }
          }
          large: childImageSharp {
            resize(width: 1440) {
              src
            }
            fluid(
              duotone: { highlight: "#193345", shadow: "#000000", opacity: 100 }
            ) {
              src
            }
          }
          relativeDirectory
          base
        }
      }
    }
  }
`
