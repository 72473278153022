import React from 'react'
import styled from 'styled-components'
import { labels } from './section-gallery'
import ModalSlider from './modal-slider'
import { brand } from '../colours'
import media from '../media'

const Container = styled.div`
  padding: 0;
  height: 100%;
  overflow: hidden;

  ${media.phablet`
    height: 100%;
    overflow: auto;
  `};
`

const Title = styled.h2`
  padding-top: 80px;
  padding-left: 100px;
  font-family: Oswald;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 2px;
  color: ${brand};

  ${media.phablet`
    font-size: 23px;
    letter-spacing: 1px;
    padding: 20px;
    margin-bottom: 0;
    padding-right: 57px;
  `};
`

const Columns = styled.div`
  position: absolute;
  top: 160px;
  right: 35px;
  bottom: 100px;
  left: 100px;
  display: flex;
  ${media.phablet`
    position: static;
    flex-direction: column;
  `};
`

const PhotosColumn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 67%;

  ${media.phablet`
    position: static;
    width: 100%;
    padding-right: 0;
  `};
`

const Description = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 33%;
  bottom: 0;
  padding-left: 40px;
  padding-bottom: 50px;
  padding-right: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  overflow: auto;

  ${media.phablet`
    position: static;
    width: 100%;
    padding: 20px;
  `};
`

const ModalProject = ({ project, photosByDirectory }) => {
  if (!project) return null
  const photos = photosByDirectory[project.node.frontmatter.directory]
  if (!photos) return null
  return (
    <Container>
      <Title>
        {labels[project.node.frontmatter.type]} -{' '}
        {project.node.frontmatter.title}
      </Title>
      <Columns>
        <PhotosColumn>
          <ModalSlider photos={photos} />
        </PhotosColumn>
        <Description dangerouslySetInnerHTML={{ __html: project.node.html }} />
      </Columns>
    </Container>
  )
}

export default ModalProject
