import React from 'react'
import styled, { css } from 'styled-components'
import media from '../media'
import { fadeIn } from '../mixins'

const Container = styled.div`
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 0;
  ${media.phone`
    flex-direction: column;
  `};
`

const Title = styled.h3`
  margin-top: 40px;
  font-weight: 200;
  font-size: 20px;
  text-align: center;
  color: #bdcfdb;
  text-transform: uppercase;
`

const Column = styled.div`
  margin-top: 40px;
  padding: 0 20px;
  color: #bdcfdb;
  flex: 1;
`

const Byline = styled.div`
  display: flex;
  float: right;
  margin-top: 50px;
`

const Icon = styled.div`
  font-family: Oswald, impact;
  font-size: 134px;
  margin-top: -48px;
  margin-bottom: -200px;
  margin-left: 20px;
`

const Who = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 2px;
`

const Where = styled.div`
  font-weight: 100;
  text-transform: uppercase;
  text-align: right;
  letter-spacing: 2px;
`

const Dots = styled.div`
  display: flex;
  flex-direction: row;
`

const Dot = styled.button`
  font-size: 0;
  padding: 0;
  opacity: 0.5;
  border: 1px solid white;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 0 6px;
  background-color: transparent;
  cursor: pointer;
  font-size: 60px;
  ${props =>
    props.isCurrent &&
    css`
      background-color: white;
      opacity: 1;
    `};
`

const FadeIn = styled.div`
  ${fadeIn};
  padding-bottom: 50px;
`

const Empty = styled.div``

const FixedHeight = styled.div`
  height: 560px;

  ${media.phablet`
    height: 750px;
  `} ${media.phone`
    height: 1150px;
  `};
`

class SectionTestimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Carousel: Empty,
    }
  }

  componentDidMount() {
    import('../../nuka-carousel.min').then(Carousel => {
      this.setState({ Carousel: Carousel.default })
    })
  }

  render() {
    const { hasEntered, slideIndex, afterSlide } = this.props
    const { Carousel } = this.state
    return (
      <FadeIn hasEntered={hasEntered}>
        <FixedHeight>
          <Carousel
            slideIndex={slideIndex}
            afterSlide={afterSlide}
            heightMode="100%"
            cellAlign="center"
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderBottomCenterControls={({
              goToSlide,
              slideCount,
              currentSlide,
            }) => (
              <Dots>
                {Array(slideCount)
                  .fill()
                  .map((_, i) => (
                    <Dot
                      key={i}
                      onClick={() => goToSlide(i)}
                      isCurrent={currentSlide === i}
                    />
                  ))}
              </Dots>
            )}
          >
            <div>
              <Title>They have exceeded our high expectations</Title>
              <Container>
                <Column>
                  <p>
                    Having JS Henderson builders renovate our older home has
                    been an excellent experience.
                  </p>
                  <p>
                    It was very important to us that the builders be not only
                    highly skilled but also have the right attitude and
                    knowledge. We are very pleased that this was certainly the
                    case from the very start to finish. They were all so well
                    mannered and showed great respect.
                  </p>
                  <p>
                    Communication channels were always open which helped smooth
                    out the whole amazing journey. We always felt that we were
                    very well looked after with the builders being honest,
                    clean, tidy and of pleasant dispositions.
                  </p>
                </Column>
                <Column>
                  <p>
                    Their attention to detail and expert craftsmanship won them
                    an award for our home. We are so happy and proud to live in
                    what feels and looks like a brand new beautiful high quality
                    home. The builders have had decades of experience in
                    building award winning homes, because of this they were able
                    to think of solutions and ideas that we loved and definitely
                    helped make the whole process so much easier.
                  </p>
                  <p>
                    They have exceeded our high expectations and the end result
                    is truly amazing.
                  </p>
                  <p>We highly recommend JS Henderson builders.</p>
                  <Byline>
                    <div>
                      <Who>Peter and Gary</Who>
                      <Where>Lennox Head NSW</Where>
                    </div>
                    <Icon>“</Icon>
                  </Byline>
                </Column>
              </Container>
            </div>
            <div>
              <Container>
                <Column>
                  <p>
                    JS Henderson has been our builder of choice for a period
                    exceeding 30 years, completing renovations, major extensions
                    and new home construction. From the initial meeting to the
                    final handover, each stage is discussed in detail, with full
                    cost disclosure. The business ethics are second to none,
                    resulting in attention to detail in every aspect of the
                    construction process.
                  </p>

                  <p>
                    This company employs a skilled and dedicated team, which
                    together with carefully selected trades, resulting in a
                    build of the highest standard.
                  </p>
                </Column>
                <Column>
                  <p>
                    Adam guided us through each phase of the construction of our
                    new home, completed in 2017. This company comes highly
                    recommended.
                  </p>
                  <Byline>
                    <div>
                      <Who>Kevin and Margaret</Who>
                      <Where>Ballina, NSW</Where>
                    </div>
                    <Icon>“</Icon>
                  </Byline>
                </Column>
              </Container>
            </div>
            <div>
              <Container>
                <Column>
                  <p>
                    We had the good fortune to have JS Henderson as builders of
                    our new Byron Bay home.
                  </p>

                  <p>
                    We had a relatively complex design with full concrete floors
                    on both levels, corten steel cladding, roof deck, "wave”
                    timber ceilings just to name a few features.
                  </p>

                  <p>The JS Henderson team produced a top quality build.</p>
                </Column>
                <Column>
                  <p>
                    Adam, John and Simon were easy to work with and catered for
                    quite a few design changes we requested.
                  </p>
                  <p>
                    We were very happy with the home they constructed for us
                  </p>{' '}
                  <Byline>
                    <div>
                      <Who>David and Jane</Who>
                      <Where>Byron Bay, NSW</Where>
                    </div>
                    <Icon>“</Icon>
                  </Byline>
                </Column>
              </Container>
            </div>
          </Carousel>
        </FixedHeight>
      </FadeIn>
    )
  }
}

export default SectionTestimonials
