import React from 'react'
import styled, { css } from 'styled-components'
import { brand } from '../colours'
import flourish from './flourish.png'
import media from '../media'

const Container = styled.div`
  /* margin-top: 100px; */
`

const Title = styled.h2`
  margin: 0;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 8px;
  text-transform: lowercase;
  font-weight: bold;
  font-size: 64px;
  text-align: center;
  color: ${props => (props.reversed ? '#bdcfd8' : brand)};

  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: 1000ms;
  transition-delay: 0ms;

  opacity: 0;
  transform: translateY(10px);

  ${props =>
    props.hasEntered &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  ${media.phone`
    font-size: 38px;
  `};
`

const SecondaryTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 18px;
  font-weight: 100;
  color: ${props => (props.reversed ? '#bdcfd8' : brand)};

  padding-top: 100px;

  transition-property: all;
  transition-timing-function: ease-in;
  transition-duration: 1000ms;
  transition-delay: 0ms;

  opacity: 0;
  transform: translateY(-5px);

  ${props =>
    props.hasEntered &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  ${media.phone`
    padding-top: 50px;
  `};
`

const Flourish = styled.div`
  width: 136px;
  height: 24px;
  margin: 0 auto;
  background-image: url(${flourish});
  background-repeat: no-repeat;
`

const SectionTitle = ({
  children,
  secondary,
  flourish = true,
  reversed = false,
  hasEntered,
}) => (
  <Container>
    {flourish && <Flourish />}
    <SecondaryTitle reversed={reversed} hasEntered={hasEntered}>
      {secondary}
    </SecondaryTitle>
    <Title reversed={reversed} hasEntered={hasEntered}>
      {children}
    </Title>
  </Container>
)

export default SectionTitle
