import React from 'react'
import styled, { css } from 'styled-components'
import { brand } from '../colours'
import media from '../media'

const Container = styled.div`
  margin: 80px auto;
  max-width: 940px;
  display: flex;

  ${media.phone`
    flex-direction: column;
  `};
`

const Column = styled.div`
  flex: 1;
  padding-left: 10px;
  & + & {
    padding-left: 10px;
  }

  opacity: 0;
  transform: translateY(20px);

  ${props =>
    props.hasEntered &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  transition-property: all;
  transition-timing-function: ease-out;

  transition-duration: 1800ms;
  transition-delay: 200ms;

  & + & {
    transition-duration: 1500ms;
    transition-delay: 400ms;
  }
  & + & + & {
    transition-duration: 1400ms;
    transition-delay: 700ms;
  }
`

const Heading = styled.h3`
  font-weight: normal;
`

const Para = styled.p`
  font-weight: 100;
`

const SectionContactUs = ({ hasEntered }) => (
  <Container>
    <Column hasEntered={hasEntered}>
      <Heading>New Homes</Heading>
      <Para>
        We can help you through the whole process of building your new home.
      </Para>
      <Para>
        - Consultation<br />- Design<br />- Approval<br />- Construction<br />-
        Completion
      </Para>
    </Column>
    <Column hasEntered={hasEntered}>
      <Heading>Alterations & Additions</Heading>
      <Para>
        We can help you to refresh, adapt, extend and personalise your home.
      </Para>
      <Para>
        - Kitchen & Bathroom Renovations<br />- Extensions<br />- Granny Flats<br />-
        Decks & Patios
      </Para>
    </Column>
    <Column hasEntered={hasEntered}>
      <Heading>Commercial Projects</Heading>
      <Para>Small to medium specialised projects.</Para>
    </Column>
  </Container>
)

export default SectionContactUs
