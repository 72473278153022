import React from 'react'
import styled from 'styled-components'
import { brand } from '../colours'
import media from '../media'
import { fadeIn } from '../mixins'
import iconMobile from './icon-mobile.svg'
import iconCall from './icon-call.svg'
import iconEmail from './icon-email.svg'
import iconPlace from './icon-place.svg'

const Container = styled.div`
  margin: 0 auto 100px auto;
  max-width: 940px;

  ${fadeIn};
`

const Columns = styled.div`
  display: flex;
  margin: 70px 0 20px 0;

  ${media.phone`
  flex-direction: column;
`};
`

const LargeIconText = styled.div`
  color: #888;
  flex: 1;
  text-align: center;
  margin: 30px 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url(${props => props.icon});
  padding-top: 60px;
`

const FormColumns = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > * {
    flex: 1;
  }

  & > * {
    margin-left: 13px;
    margin-right: 13px;
  }
  & > * + * {
    margin-left: 0;
  }

  ${media.phone`
    flex-direction: column;
    & > * + * {
      margin-top: 16px;
      margin-left: 13px;
    }
  `};
`

const VerticalFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * + * {
    margin-top: 16px;
  }
`

const Input = styled.input`
  padding: 8px 10px;
  border: 1px solid #d8d8d8;
`

const MessageField = styled.textarea`
  padding: 8px 10px;
  border: 1px solid #d8d8d8;
`

const Submit = styled.button`
  padding: 8px 10px;
  float: right;
  background-color: ${brand};
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  border: 0;
  margin-right: 13px;
`

const SubmitNotice = styled.p`
  padding: 8px 10px;
  float: right;
  text-transform: uppercase;
  font-size: 13px;
`

class SectionContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      isSending: false,
      isSent: false,
    }
  }

  render() {
    const { hasEntered } = this.props
    const { values } = this.state
    let notice
    if (this.state.isInvalid) {
      notice = 'All fields are required'
    } else if (this.state.isSending) {
      notice = 'Sending...'
    } else if (this.state.isSent) {
      notice = 'Message Sent'
    }
    const inputProps = field => ({
      onChange: event => {
        const { value } = event.target
        this.setState(s => ({
          isInvalid: false,
          isSent: false,
          values: {
            ...s.values,
            [field]: value,
          },
        }))
      },
      value: values[field] === undefined ? '' : values[field],
    })
    return (
      <Container hasEntered={hasEntered}>
        <Columns>
          <LargeIconText icon={iconMobile}>0427 328 497</LargeIconText>
          <LargeIconText icon={iconCall}>02 6681 3769</LargeIconText>
          <LargeIconText icon={iconEmail}>
            adam@jshenderson.com.au
          </LargeIconText>
          <LargeIconText icon={iconPlace}>
            19 Chilcott Circuit<br />Cumbalum NSW 2478
          </LargeIconText>
        </Columns>
        <form
          onSubmit={event => {
            event.preventDefault()
            if (
              !values.name ||
              !values.email ||
              !values.phone ||
              !values.message
            ) {
              this.setState({ isInvalid: true })
              return
            }
            this.setState({ isSending: true, isInvalid: false })
            fetch('/mailer.php', {
              method: 'post',
              body: JSON.stringify(this.state.values),
            }).then(res => {
              this.setState({ isSending: false, isSent: true, values: {} })
            })
          }}
        >
          <FormColumns>
            <VerticalFields>
              <Input placeholder="NAME *" {...inputProps('name')} />
              <Input placeholder="EMAIL *" {...inputProps('email')} />
              <Input placeholder="PHONE *" {...inputProps('phone')} />
            </VerticalFields>
            <MessageField placeholder="MESSAGE *" {...inputProps('message')} />
          </FormColumns>
          <Submit type="submit" disabled={this.state.isSending}>
            Send Message
          </Submit>
          <SubmitNotice>{notice}</SubmitNotice>
        </form>
      </Container>
    )
  }
}

export default SectionContactUs
