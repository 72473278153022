import React from 'react'
import styled, { css } from 'styled-components'
import media from '../media'
import { fadeIn } from '../mixins'
import award2010 from './award-2010.png'
import award2011 from './award-2011.png'
import award2012 from './award-2012.png'
import award2017 from './award-2017.png'

const Container = styled.div`
  max-width: 940px;
  margin: 50px auto;

  ${fadeIn};
`

const Row = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-around;
  padding-bottom: 50px;
  & > img {
    width: 22%
    max-width: 200px;
  }

  ${media.phone`
    flex-direction: column;
    & > img {
      width: 100%
    }
  `};

  ${props =>
    props.center &&
    css`
      align-items: center;
    `};
`

const Col = styled.div`
  flex: 1;
`

const ListCol = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 100;
  letter-spacing: 1px;

  & > li {
    margin: 0;
  }

  ${media.phone`
    font-size: 11px;
  `};
`

const SectionAwards = ({ hasEntered }) => (
  <Container hasEntered={hasEntered}>
    <Row>
      <ListCol>
        <li>2017 - Best Contract Home 1.5 - 2.0 Million</li>
        <li>2017 - Merit Award Best Addition $300K - $500K</li>
        <li>2012 - Best Contract Home Over $2 Million</li>
        <li>2012 - Best Swimming Pool Over $100K</li>
        <li>2012 - Best Rural Homestead $700K - $1 Million</li>
        <li>2011 - Project of the Year - Residential</li>
        <li>2011 - Best Contract Home $1 - $3 Million</li>
      </ListCol>
      <ListCol>
        <li>2010 - Builder of the Year - Residential</li>
        <li>2010 - Best Contract Home $1.5 - $2 Million</li>
        <li>2010 - Best Commercial Project up to $500K</li>
        <li>2010 - Best Contract Home $750K - $1 Million</li>
        <li>2009 - Best Contract Home Over $3 Million</li>
        <li>2008 - Best Addition $150K - $250K (State Award)</li>
        <li>2008 - Best Addition $200K - $300K</li>
      </ListCol>
    </Row>
    <Row center>
      <img src={award2017} alt="Regional Building Awards - Winner 2017" />
      <img src={award2012} alt="Regional Building Awards - Winner 2012" />
      <img src={award2011} alt="Regional Building Awards - Winner 2011" />
      <img src={award2010} alt="Regional Building Awards - Winner 2010" />
    </Row>
  </Container>
)

export default SectionAwards
