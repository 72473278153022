import React from 'react'
import styled from 'styled-components'
import Waypoint from 'react-waypoint'

class Section extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasEntered: false }
  }
  render() {
    const { id } = this.props
    const { hasEntered } = this.state
    return (
      <div id={id}>
        <div style={{ paddingTop: 1 }}>
          <Waypoint
            onEnter={event => {
              this.props.onSectionWaypoint({ id, event, action: 'enter' })
              this.setState(s => s.hasEntered === false && { hasEntered: true })
            }}
            onLeave={event => {
              this.props.onSectionWaypoint({ id, event, action: 'leave' })
            }}
            topOffset="-100"
          />
          <div style={{ marginTop: -1, minHeight: 720 }}>
            {this.props.children instanceof Function
              ? this.props.children({ hasEntered })
              : this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default Section
