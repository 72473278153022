import React from 'react'
import styled, { css } from 'styled-components'
import logo from './logo.png'
import logoCompact from './logo-compact.png'

const HeaderLogo = styled.h1`
  display: block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;

  ${props =>
    props.compact
      ? css`
          background-image: url(${logoCompact});
          width: 120px;
          height: 38px;
        `
      : css`
          background-image: url(${logo});
          width: 50px;
          height: 50px;
        `};
`

export default HeaderLogo
