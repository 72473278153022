import React from 'react'
import styled, { css } from 'styled-components'
import thumbBg from './thumb-bg.jpg'
import media from '../media'
import { fadeIn } from '../mixins'

export const labels = {
  all: 'All',
  new: 'New Construction',
  reno: 'Renovation',
  special: 'Special Design & Construction',
}

const Container = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fadeIn};
`

const Filters = styled.div`
  display: flex;
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: wrap;
`

const FilterOption = styled.button`
  margin: 0 0 20px 0;
  padding: 0;
  border: 0;
  display: block;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  color: #888;
  cursor: pointer;
  white-space: nowrap;
  font-size: 13px;
  border-bottom: 1px solid white;

  &:focus {
    outline: 0;
  }

  ${props =>
    props.selected &&
    css`
      color: black;
      border-bottom: 1px solid black;
    `};
`

const MoreButton = styled.button`
  margin: 0;
  padding: 8px 10px;
  border: 1px solid #888;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  color: #888;
  cursor: pointer;
  white-space: nowrap;
  font-size: 13px;

  &:focus {
    outline: 0;
  }
`

const Gallery = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0 -0.69vw 20px -0.69vw;
`

const Thumb = styled.div`
  margin: 1.88vw 0.69vw;
  cursor: pointer;
  flex-grow: 0;
  flex-basis: 30%;
  // max-width: 400px;

  ${media.phone`
    flex-basis: 45%;
  `};
`

const ThumbGraphic = styled.div`
  position: relative;
  padding: 10% 10% 4% 10%;
  margin-bottom: 4%;
  background-image: url(${thumbBg});
  background-size: cover;
`

const ThumbOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  background-image: radial-gradient(
    50% 170%,
    #fff 44%,
    #e6e6e6 120%,
    rgba(0, 0, 0, 0) 120%
  );
`

const ThumbImage = styled.div`
  position: relative;
  padding-bottom: 65%;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
  background-size: cover;
  ${props =>
    props.image &&
    css`
      background-image: url("${props.image}");
    `};
`

const ThumbTitle = styled.h3`
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  color: #888;
  letter-spacing: 4px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`
const ThumbSubtitle = styled.div`
  margin: 0;
  padding: 5px;
  background-color: white;
  position: relative;
  text-transform: uppercase;
  color: #888;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

function filterProjects({ filter, projects }) {
  if (filter === 'all') {
    return projects
  }
  return projects.filter(e => e.node.frontmatter.type === filter)
}

function getPosterPhoto({ photosByDirectory, project }) {
  const photos = photosByDirectory[project.node.frontmatter.directory]
  const { poster } = project.node.frontmatter
  if (Array.isArray(photos) && poster) {
    const photo = photos.find(p => p.node.base === poster)
    return photo.node.thumb.resize.src
  }
  if (Array.isArray(photos) && photos.length >= 1) {
    return photos[0].node.thumb.resize.src
  }
  return undefined
}

class SectionGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: 'all',
      more: false,
    }
  }

  render() {
    const { photosByDirectory, hasEntered } = this.props

    const filteredProjects = filterProjects({
      filter: this.state.filter,
      projects: this.props.projects,
    })

    const limitedFilteredProjects = this.state.more
      ? filteredProjects
      : filteredProjects.slice(0, 6)

    return (
      <Container hasEntered={hasEntered}>
        <Filters>
          {['all', 'new', 'reno', 'special'].map(option => (
            <FilterOption
              key={option}
              selected={this.state.filter === option}
              onClick={() => {
                this.setState({ filter: option })
                if (
                  filterProjects({
                    filter: option,
                    projects: this.props.projects,
                  }).length <= 6
                ) {
                  this.setState({ more: false })
                }
              }}
            >
              {labels[option]}
            </FilterOption>
          ))}
        </Filters>
        <Gallery>
          {limitedFilteredProjects.map(project => (
            <Thumb
              key={project.node.id}
              onClick={() =>
                this.props.onOpenProject(project.node.frontmatter.slug)
              }
            >
              <ThumbGraphic>
                <ThumbOverlay />
                <ThumbImage
                  image={getPosterPhoto({ photosByDirectory, project })}
                />
              </ThumbGraphic>
              <ThumbTitle>{project.node.frontmatter.title}</ThumbTitle>
              <ThumbSubtitle>
                {labels[project.node.frontmatter.type]}
              </ThumbSubtitle>
            </Thumb>
          ))}
        </Gallery>
        {filteredProjects.length > 6 && (
          <MoreButton onClick={() => this.setState(s => ({ more: !s.more }))}>
            See {this.state.more ? 'Less' : 'More'}
          </MoreButton>
        )}
      </Container>
    )
  }
}

export default SectionGallery
