import React from 'react'
import styled from 'styled-components'

const RedHighlight = styled.strong`
  background-color: #9e0214;
  border-radius: 20px;
  padding: 0 8px;
  margin: 0 3px;
  font-weight: normal;
  color: white;
  white-space: nowrap;
`

const PhoneHighlighted = () => <RedHighlight>0427 328 497</RedHighlight>

export default PhoneHighlighted
