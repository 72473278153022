import React from 'react'
import styled, { keyframes } from 'styled-components'
import Link from 'gatsby-link'
import HeaderLogo from './header-logo'
import PhoneHighlighted from './phone-highlighted'
import { brand } from '../colours'
import media from '../media'

const slideIn = keyframes`
  from {
    transform: translateY(-100%)
  }

  to {
    transform: translateY(0)
  }
`

const Container = styled.div`
  transition: all 700ms ease;
  position: ${props => (props.floating ? 'fixed' : 'absolute')};
  height: ${props => (props.floating ? '55px' : '80px')};
  top: 0;
  right: 0;
  left: 0;
  padding: 10px;
  background-color: ${props =>
    props.floating ? 'white' : 'rgba(0, 0, 0, 0.3)'};
  box-shadow: 0 -1px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: 300ms ease-out 0s 1 ${slideIn};
  z-index: 2000;

  ${media.phone`
    background-color: ${brand};
  `};
`

const Anchor = styled.a`
  text-decoration: none;
`

const FloatHeader = ({ siteTitle, floating, children, hideCta }) => (
  <Container floating={floating}>
    <Anchor href="#top">
      <HeaderLogo compact={floating}>{siteTitle}</HeaderLogo>
    </Anchor>
    {children}
    {!hideCta && floating && <div style={{ width: 120 }} />}
  </Container>
)

export default FloatHeader
